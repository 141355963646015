import React from 'react';

import { Badge, Grid, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Typography, List, ListItem, ListItemText, Drawer, ListItemIcon } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Brightness4Icon from '@material-ui/icons/Brightness4';

// import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleDrawer: false
        }

        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    UNSAFE_componentWillMount() {
        var userData = JSON.parse(localStorage.getItem("userData"));

        this.setState({ userData: userData.data });
    }

    toggleDrawer = (toggleDrawer) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }
    
        this.setState({ toggleDrawer });
    };

    toggleMenu = (event) => {
        this.setState({ menuComponent: event.currentTarget });
    };

    closeMenu = () => {
        // setTimeout(()=>{this.setState({ menuComponent: null })}, 0)
        this.setState({ menuComponent: null });
    };

    render() {
        var userData = this.state.userData;
        var theme = this.props.theme;     

        return (
            <div className="header" style={{zIndex:1000, position: "fixed", width: "100%" }}>
                <Drawer style={{display: "flex", flexDirection: "column"}} open={this.state.toggleDrawer} onClose={()=>this.toggleDrawer(false)}>    
                    <div
                        style={{width: "250px", display: "flex", flexDirection: "column", height: "100%"}}
                        role="presentation"
                        onClick={()=>this.toggleDrawer(false)}
                        onKeyDown={()=>this.toggleDrawer(false)}
                    >
                        <div style={{backgroundColor: "rgb(109, 19, 226)", color: "white"}}>
                            <List>
                                {/* <h5>Profile</h5> */}
                                <ListItem style={{paddingTop: "20px"}}>
                                    <ListItemText>
                                        <Typography style={{fontWeight: "50px"}} variant="h6">{userData.name}</Typography>
                                        <Typography style={{opacity: "0.8"}} variant="subtitle2">{userData.email}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </div>
                        {/* <Divider /> */}
                        <div style={{backgroundColor: theme.backgroundColor, height: "100%", color: theme.color}}>
                            <List>
                                <ListItem button onClick={()=>{this.props.changePage("dashboard")}}>
                                    <ListItemIcon><AssignmentIcon style={{color: theme.color}} /></ListItemIcon>
                                    <ListItemText style={{marginLeft: "-20px"}} primary={"Dashboard"} />
                                </ListItem>
                                <ListItem button onClick={()=>{this.props.changePage("profile")}}>
                                    <ListItemIcon><AccountCircleIcon style={{color: theme.color}} /></ListItemIcon>
                                    <ListItemText style={{marginLeft: "-20px"}} primary={"Profile"} />
                                </ListItem>
                                <ListItem button onClick={()=>{this.props.changePage("transactions")}}>
                                    <ListItemIcon><SwapHorizIcon style={{color: theme.color}} /></ListItemIcon>
                                    <ListItemText style={{marginLeft: "-20px"}} primary={"Transactions"} />
                                </ListItem>
                                <ListItem button onClick={()=>{this.props.changePage("notifications")}}>
                                    <ListItemIcon><NotificationsActiveIcon style={{color: theme.color}} /></ListItemIcon>
                                    <ListItemText style={{marginLeft: "-20px"}} primary={"Notifications"} />
                                </ListItem>
                            </List>

                            <div style={{position: "absolute", marginTop: "auto", width: "100%", bottom: 20, display: "flex", justifyContent: "space-between"}}>
                                <ListItemIcon
                                    onClick={()=>{this.props.logout()}}
                                    style={{marginLeft: "auto", marginRight: "auto", display: "block", textAlign: "center"}}>
                                    <PowerSettingsNewIcon style={{color: theme.color}} />
                                    <div style={{textAlign: "center", color: theme.color}}>Logout</div>
                                </ListItemIcon>
                                <ListItemIcon 
                                    onClick={()=>{this.props.toggleTheme();}} 
                                    style={{marginLeft: "auto", marginRight: "auto", display: "block", textAlign: "center"}}>
                                    <Brightness4Icon style={{color: theme.color}} />
                                    <div style={{textAlign: "center", color: theme.color}}>{(theme.color === "white")?"Light Mode":("Dark Mode")}</div>
                                </ListItemIcon>
                            </div>
                        </div>
                    </div>
                </Drawer>

                {/* <Menu
                    id="fade-menu"
                    anchorEl={this.state.menuComponent}
                    keepMounted
                    open={Boolean(this.state.menuComponent)}
                    onClose={this.closeMenu}
                    TransitionComponent={Fade}
                    transitionDuration={300}
                >
                    <MenuItem onClick={this.closeMenu}>Developer Options</MenuItem>
                    <MenuItem onClick={this.closeMenu}>My account</MenuItem>
                    <MenuItem onClick={()=>{this.closeMenu(); this.props.logout();}}>Logout</MenuItem>
                </Menu> */}




                <AppBar style={{backgroundColor: "rgb(109, 19, 226)"}} position="static">
                    <Toolbar>
                        <Grid item xs={3}>
                            <IconButton
                                onClick={()=>this.toggleDrawer(true)} 
                                style={{outline: "none"}} edge="start" color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <center style={{marginTop: "3px"}}>TollPlaza</center>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton
                                onClick={()=>{this.props.changePage("notifications")}}
                                style={{outline: "none" ,float: "right"}} edge="start" color="inherit" aria-label="menu"
                            >
                                <Badge style={{transform: "scale(0.9)"}} badgeContent={this.props.notificationCount} color="error">
                                    <NotificationsIcon  style={{transform: "scale(1.2)"}} />
                                </Badge>
                            </IconButton>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default Header;