import React from 'react';

import { Button, TextField } from '@material-ui/core';

import './Error500.css'

class Error500 extends React.Component {
    constructor(props) {
        super(props);

        this.handleHostChange = this.handleHostChange.bind(this);
        this.setHost = this.setHost.bind(this);

        this.state = {
            host: ""
        }
        
    }

    UNSAFE_componentWillMount() {
        var host = JSON.parse(localStorage.getItem('host'));
        this.setState({ host });
    }

    componentDidMount() {
        
    }

    handleHostChange(e) {
        this.setState({ host: e.target.value });
    }

    setHost() {
        // console.log(this.state.host);
        localStorage.setItem('host', JSON.stringify(this.state.host));
        window.location.reload();
    }

    render() {

        return (
            <>
                <div className="container">
                <div className="error">
                    {/* <h1>500</h1> */}
                    {/* <h2>error</h2> */}
                    <p>Please Check your internet connection. If it doesn't work, contact admin.</p>
                    <TextField onChange={this.handleHostChange} value={this.state.host} style={{textAlign: "center"}} label="Server address" />
                    <center><Button onClick={this.setHost} style={{width: "100px", margin: "20px 0"}} color="primary" variant="contained">Connect</Button></center>
                </div>
                <div className="stack-container">
                    <div className="card-container pokeup">
                        <div className="perspec explode" style={{"--spreaddist": "125px",  "--scaledist": ".75", "--vertdist": "-25px"}}>
                            <div className="card">
                                <div className="writing">
                                    <div className="topbar">
                                        <div className="red"></div>
                                        <div className="yellow"></div>
                                        <div className="green"></div>
                                    </div>
                                    <div className="code"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-container pokeup">
                        <div className="perspec explode" style={{"--spreaddist": "100px", "--scaledist": ".8", "--vertdist": "-20px"}}>
                            <div className="card">
                                <div className="writing">
                                    <div className="topbar">
                                        <div className="red"></div>
                                        <div className="yellow"></div>
                                        <div className="green"></div>
                                    </div>
                                    <div className="code"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-container pokeup">
                        <div className="perspec explode" style={{"--spreaddist":"75px", "--scaledist": ".85", "--vertdist": "-15px"}}>
                            <div className="card">
                                <div className="writing">
                                    <div className="topbar">
                                        <div className="red"></div>
                                        <div className="yellow"></div>
                                        <div className="green"></div>
                                    </div>
                                    <div className="code"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-container pokeup">
                        <div className="perspec explode" style={{"--spreaddist": "50px", "--scaledist": ".9", "--vertdist": "-10px"}}>
                            <div className="card">
                                <div className="writing">
                                    <div className="topbar">
                                        <div className="red"></div>
                                        <div className="yellow"></div>
                                        <div className="green"></div>
                                    </div>
                                    <div className="code"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-container pokeup">
                        <div className="perspec explode" style={{"--spreaddist": "25px", "--scaledist": ".95", "--vertdist": "-5px"}}>
                            <div className="card">
                                <div className="writing">
                                    <div className="topbar">
                                        <div className="red"></div>
                                        <div className="yellow"></div>
                                        <div className="green"></div>
                                    </div>
                                    <div className="code"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-container pokeup">
                        <div className="perspec explode" style={{"--spreaddist": "0px", "--scaledist": "1", "--vertdist": "0px"}}>
                            <div className="card">
                                <div className="writing">
                                    <div className="topbar">
                                        <div className="red"></div>
                                        <div className="yellow"></div>
                                        <div className="green"></div>
                                    </div>
                                    <div className="code">
                                        <ul>
                                        <div className="errorcode">
                                            <h1>500</h1>
                                            <h2>error</h2>
                                            <h3>Could not connect to TollPlaza server.</h3>
                                        </div>
                                        {/* <li className="node-0 writeLine" style={{"--linelength": "33%"}}></li> */}
                                        {/* <li className="node-1 writeLine" style={{"--linelength": "88%"}}></li> */}
                                        {/* <li className="node-2 writeLine" style={{"--linelength": "85%"}}></li> */}
                                        {/* <li className="node-3 writeLine" style={{"--linelength": "82%"}}></li> */}
                                        {/* <li className="node-4 writeLine" style={{"--linelength": "77%"}}></li> */}
                                        {/* <li className="node-5 writeLine" style={{"--linelength": "38%"}}></li> */}
                                        {/* <li className="node-6 writeLine" style={{"--linelength": "41%"}}></li> */}
                                        {/* <li className="node-7 writeLine" style={{"--linelength": "35%"}}></li> */}
                                        {/* <li className="node-8 writeLine" style={{"--linelength": "89%"}}></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default Error500;
