import React from 'react';

import axios from 'axios';

import { Snackbar, Button, TextField, ThemeProvider, createMuiTheme } from '@material-ui/core';


class AddMoney extends React.Component {
    constructor(props) {
        super(props);

        this.handleMoneyTextField = this.handleMoneyTextField.bind(this);
        this.addMoney = this.addMoney.bind(this);

        this.showMessage = this.showMessage.bind(this);
        this.hideMessage = this.hideMessage.bind(this);

        this.state = {
            money: ""
        }
    }

    handleMoneyTextField(e) {
        if (e.target.value >= 0) this.setState({ money: e.target.value });
    }

    async addMoney() {
        var host = JSON.parse(localStorage.getItem('host'));
        var cid = JSON.parse(localStorage.getItem('userData')).data.cid;
        var money = this.state.money

        var res = await axios.post(host+'/api/customers/addMoney.php', { cid, money }).catch(()=>{
            this.showMessage("Transaction Failed");
        });
        
        if (res) {
            if (!res.data.error) {
                this.showMessage("Added Money Successfully");
                this.setState({ money: "" })
            }
            else {
                this.showMessage("Transaction Failed");
            }
        }

    }

    showMessage(message) {
        this.setState({ showSuccessMessage: true, snackMessage: message, showPrintPreview: true });
    }

    hideMessage() {
        this.setState({ showSuccessMessage: false });
    }

    render() {
        const theme = createMuiTheme({
            palette: {
              type: this.props.themeName
            }
        });
        // var theme = this.props.theme;
        // console.log(theme)
        return (
            <div style={{padding: "20px 0", paddingTop: "80px"}}>

                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.showSuccessMessage}
                    onClose={this.hideMessage}
                    message={this.state.snackMessage}
                    autoHideDuration={2000}
                />

                <ThemeProvider theme={theme}>
                    <center>
                        <div style={{textAlign: "center", fontSize: "25px"}}>Add Money</div>
                    </center>

                    <div style={{textAlign: "center", marginTop: "80px"}} >
                        <TextField type="number" value={this.state.money} onChange={this.handleMoneyTextField} id="textField" style={{ color: "inherit" }} />
                    </div>

                    <div className="update" style={{textAlign: "center", marginTop: "40px"}}>
                        <Button onClick={this.addMoney} style={{margin: "10px", outline: "none", backgroundColor: "#e56219"}} color="primary" variant="contained">Add</Button>
                    </div>
                </ThemeProvider>
            </div>
        );
    }
}

export default AddMoney;