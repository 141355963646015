import React from 'react';

import $ from 'jquery';

import { TextField, Button } from '@material-ui/core';

import './Login.css';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        document.getElementsByClassName('login')[0].style.height = document.getElementsByClassName('login')[0].clientHeight.toString()+"px";
    }

    render() {
        return (
            <div className="login">
                <svg style={{position: "absolute"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" preserveAspectRatio="none" width="100%" height="100%">
                    <polygon points="0,0  1000,0  0,350" style={{fill: "#6d13e2"}} />
                    <polygon points="0,1000  1000,650  1000,1000" style={{fill: "#6d13e2"}} />
                </svg>
                <div className="block">
                    <div className="logo">TollPlaza</div>

                    <div className="login-form">
                        <div className="form-group">
                            <TextField id="email" type="email" label="Email" fullWidth />
                        </div>
                        <div className="form-group">
                            <TextField id="password" type="password" label="Password" fullWidth />
                        </div>
                        <Button 
                            style={{outline: "none"}} 
                            variant="contained" 
                            color="primary"
                            onClick={()=> {this.props.login($('#email').val(), $('#password').val())}}
                        >Login</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;