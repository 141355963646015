import React from 'react';

import config from "../../../config.json";
// import $ from 'jquery';

import { Paper, Grid, ListItemIcon, IconButton, CardActionArea } from '@material-ui/core';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import CommuteIcon from '@material-ui/icons/Commute';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
// import ClearIcon from '@material-ui/icons/Clear';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.startPoll = this.startPoll.bind(this);
        this.loadComponents = this.loadComponents.bind(this);
        this.goToConfirmationPage = this.goToConfirmationPage.bind(this);

        this.state = {
            cid: 0,
            componentUpdated: 0,
            vehiclesComponent: [],
            transactionsComponent: []
        }

        this.cid = 0;
    }

    async UNSAFE_componentWillMount() {
        var userData = JSON.parse(localStorage.getItem("userData"));

        this.cid = userData.data.cid;
        var res = await this.props.getTransactions(this.cid);
        this.loadComponents(res.data.data);
        this.setState({ userData: userData.data, transactions: res.data.data });
        
        // console.log(userData.data.cid);
        
        this.poll = setInterval(this.startPoll, config.pollTime);
        // console.log(this.state);

    }

    shouldComponentUpdate(nextProps, nextState) {
        if ((JSON.stringify(nextState.transactions) === JSON.stringify(this.state.transactions)) 
            && (JSON.stringify(nextState.vehicles) === JSON.stringify(this.state.vehicles))
            && (JSON.stringify(nextState.recentTransactions) === JSON.stringify(this.state.recentTransactions))) {
            // console.log(nextState.transactions, this.state.transactions);
            return false;
        }
        else {
            // console.log("Database Upadated");
            // console.log(nextState.transactions);
            this.loadComponents(nextState.transactions);
        }
        return true;
    }

    loadComponents(transactions) {
        var vehicles = {};
        var vehiclesComponent = [];
        var recentTransactions = {};
        var transactionsComponent = [];
        var recentTransactionsCount = 0;
        var key = null;
        var notificationCount = 0;

        for (key in transactions) {
            // console.log(transactions[key]);
            if (transactions[key].confirmed === "0") notificationCount++;
            
            if (recentTransactionsCount < 3) {
                recentTransactions[transactions[key].txnID] = transactions[key];
                
                transactionsComponent.push(
                    <Paper
                        txnid={transactions[key].txnID}
                        onClick={this.goToConfirmationPage}
                        style={{width: "95%", height: "100px", textAlign: "left", marginTop: "10px", backgroundColor: "inherit", color: "inherit"}} elevation={3}>
                        <CardActionArea style={{height: "100%", padding: "10px", outline: "none"}}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div style={{fontWeight: "bold"}}>{transactions[key].vehicleNo} ({transactions[key].vehicleType})</div>
                                    <div style={{fontSize: "15px"}}>{transactions[key].location}</div>
                                    <div style={{fontSize: "15px"}}>{transactions[key].timestamp}</div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{float: "right", fontSize: "20px", margin: "20px 10px 0 0"}}>₹ {transactions[key].charge}</div>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Paper>
                );

                recentTransactionsCount++;
            }

            if (vehicles[transactions[key].vehicleNo]) {
                if (vehicles[transactions[key].vehicleNo][1] !== "0") {
                    vehicles[transactions[key].vehicleNo] = [transactions[key].vehicleType, transactions[key].confirmed];
                }
            }
            else {
                vehicles[transactions[key].vehicleNo] = [transactions[key].vehicleType, transactions[key].confirmed];
            }
        }

        for (key in vehicles) {
            // console.log(vehicles[key]);
            vehiclesComponent.push(
                <Paper style={{width: "95%", height: "100px", textAlign: "left", marginTop: "10px", backgroundColor: "inherit", color: "inherit"}} elevation={3}>
                    <CardActionArea style={{height: "100%", padding: "10px", outline: "none"}}>
                        <Grid container>
                            <Grid item xs={9}>
                                <div style={{}}>{vehicles[key][0]}</div>
                                <ListItemIcon style={{float: "left"}}><CommuteIcon style={{fill: "#7562e3", marginTop: "5px", width: "45px", height: "45px"}} /></ListItemIcon>
                                <div style={{fontSize: "23px", marginTop: "10px"}}>{key}</div>
                            </Grid>
                            <Grid item xs={3}>
                                {
                                    (vehicles[key][1] === "0")
                                    ?(<ListItemIcon style={{float: "right"}}><InfoOutlinedIcon style={{ fill: "#ff7400", marginTop: "25px", width: "35px", height: "35px"}} /></ListItemIcon>)
                                    :(<ListItemIcon style={{float: "right"}}><CheckIcon style={{fill: "#7562e3", marginTop: "25px", width: "35px", height: "35px"}} /></ListItemIcon>)
                                }
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Paper>
            );
        }

        this.props.updateNotificationCount(notificationCount);

        // console.log(transactions)
        this.setState({ vehicles, vehiclesComponent, transactionsComponent, recentTransactions });
    }

    async startPoll() {
        var res = await this.props.getTransactions(this.cid);
        // console.log(res.data.data);
        this.setState({ transactions: res.data.data });

    }
    
    stopPoll() {
        clearInterval(this.poll);
    }

    componentWillUnmount() {
        this.stopPoll();
    }

    goToConfirmationPage(e) {
        var node = e.target;
        while(!node.attributes.txnid) node = node.parentNode;
        // console.log(node.attributes.txnID.value)
        // console.log(this.state.allTransactions[node.attributes.txnID.value])
        this.props.goToConfirmPage(this.state.recentTransactions[node.attributes.txnid.value]);
    }


    render() {
        // var userData = this.state.userData;
        var transactions = this.state.transactions;
        // var userData = JSON.parse(localStorage.getItem('userData'));
        // var balanceAmount = userData.data.balanceAmount;
        // if (transactions) console.log(transactions[0].balanceAmount);
        // var vehicles = this.state.vehicles;

        return (
            <div style={{padding: "20px 0", paddingTop: "80px"}}>
                <center>
                    <div style={{textAlign: "center", fontSize: "23px"}}>Dashboard</div>

                    <div className="walletAmount" style={{marginTop: "10px"}}>
                        <Paper style={{width: "95%", height: "100px", textAlign: "left", padding: "10px", backgroundColor: "inherit", color: "inherit"}} elevation={3}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <div style={{fontWeight: "bold", pointerEvents: "none"}}>Wallet Amount</div>
                                    <ListItemIcon style={{float: "left"}}><AccountBalanceWalletIcon style={{fill: "#7562e3", marginTop: "5px", width: "45px", height: "45px"}} /></ListItemIcon>
                                    <div style={{fontSize: "25px", marginTop: "10px", pointerEvents: "none"}}>₹ {(transactions) && transactions[0].balanceAmount}</div>
                                </Grid>
                                <Grid item xs={3}>
                                    <IconButton onClick={this.props.addMoney} style={{float: "right", marginTop: "17px", outline: "none"}}><AddCircleOutlineIcon style={{fill: "#7562e3", width: "40px", height: "40px"}} /></IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>

                    <div className="vehicles">
                        <div style={{margin: "30px 0 10px 0", fontSize: "17px",}}>Vehicles</div>
                       
                        {(this.state.vehicles) && (this.state.vehiclesComponent)}

                    </div>
                
                    <div className="RecentTransactions">
                        <div style={{margin: "30px 0 10px 0", fontSize: "17px",}}>Recent Transactions</div>
                        
                        {(this.state.recentTransactions) && (this.state.transactionsComponent)}
                        
                    </div>
                </center>
            </div>
        );
    }
}

export default Dashboard;