import React from 'react';

// import config from "../../config.json";
// import $ from 'jquery';
import axios from 'axios';

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from '../Theme';
import { GlobalStyles } from '../GlobalStyles';

import { Backdrop, CircularProgress } from '@material-ui/core';

import Header from './Header';

import Dashboard from './Pages/Dashboard';
import Profile from './Pages/Profile';
import Transactions from './Pages/Transactions';
import Notifications from './Pages/Notifications';
import ConfirmPage from './Pages/ConfirmPage';
import AddMoney from './Pages/AddMoney';

class DashboardIndex extends React.Component {
    constructor(props) {
        super(props);

        this.loadInitTheme = this.loadInitTheme.bind(this);
        this.toggleTheme = this.toggleTheme.bind(this);
        this.changePage = this.changePage.bind(this);
        this.goToConfirmPage = this.goToConfirmPage.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.updateNotificationCount = this.updateNotificationCount.bind(this);
        this.addMoney = this.addMoney.bind(this);
        
        this.pages = {
            Dashboard: <Dashboard addMoney={this.addMoney} updateNotificationCount={this.updateNotificationCount} getTransactions={this.getTransactions} goToConfirmPage={this.goToConfirmPage} />,
            Profile: <Profile />,
            Transactions: <Transactions updateNotificationCount={this.updateNotificationCount} getTransactions={this.getTransactions} goToConfirmPage={this.goToConfirmPage} />,
            Notifications: <Notifications updateNotificationCount={this.updateNotificationCount} getTransactions={this.getTransactions} goToConfirmPage={this.goToConfirmPage} />
        };

        this.state = {
            loading: true, 
            themeName: "",
            theme: null,
            content: this.pages.Dashboard,
            notificationCount: 0
            
        };
    }

    UNSAFE_componentWillMount() {
        this.loadInitTheme();
    }

    componentDidMount() {
        this.setState({ loading: false });
    }
    
    loadInitTheme() {
        var localTheme = localStorage.getItem('theme');
        var themeName = "light";
        var theme = lightTheme;
        if (localTheme) {
            if (localTheme === "dark") {
                themeName = "dark";
                theme = darkTheme;
            }
        }
        else {
            localStorage.setItem('theme', themeName);
        }
        this.setState({ themeName, theme });
    }

    toggleTheme() {
        var localTheme = localStorage.getItem('theme');
        var themeName = "";
        var theme = null;

        if (localTheme === "light") {
            themeName = "dark";
            theme = darkTheme;
        }
        else if (localTheme === "dark") {
            themeName = "light";
            theme = lightTheme;
        }

        localStorage.setItem('theme', themeName);
        this.setState({ themeName, theme });
    }

    changePage (page) {
        var content = null;

        if (page === "dashboard") content = this.pages.Dashboard;
        else if (page === "profile") content = this.pages.Profile;
        else if (page === "transactions") content = this.pages.Transactions;
        else if (page === "notifications") content = this.pages.Notifications;

        this.setState({ content });
    }

    goToConfirmPage(transactionDetails) {
        this.setState({ content: <ConfirmPage theme={this.state.theme} transactionDetails={transactionDetails} /> })
    }

    async getTransactions(cid) {
        var host = JSON.parse(localStorage.getItem('host'));

        var res = await axios.post(host+'/api/customers/getTransactions.php', { cid });

        if (res) {
            return res;
        }
        else {
            
        }
    }

    updateNotificationCount(notificationCount) {
        this.setState({ notificationCount });
    }

    addMoney() {
        this.setState({ content: <AddMoney themeName={this.state.themeName} /> })
    }
    

    render() {

        return (
            <>

                <Backdrop style={{zIndex: 1000, backgroundColor: "inherit", color: "inherit"}} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <ThemeProvider theme={this.state.theme}>
                    <GlobalStyles />

                    <Header
                        theme={this.state.theme}
                        toggleTheme={this.toggleTheme}
                        notificationCount={this.state.notificationCount}
                        changePage={this.changePage}
                        logout={this.props.logout} 
                    />

                    {this.state.content}
                </ThemeProvider>
            </>
        );
    }
}

export default DashboardIndex;