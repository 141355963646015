import React from 'react';

import config from "../../../config.json";

import { Paper, Grid, CardActionArea, ListItemIcon } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

class Transactions extends React.Component {
    constructor(props) {
        super(props);

        this.startPoll = this.startPoll.bind(this);
        this.loadComponents = this.loadComponents.bind(this);
        this.goToConfirmationPage = this.goToConfirmationPage.bind(this);

        this.state = {
            allTransactions: [],
            transactionsComponent: []
        }
    }

    async UNSAFE_componentWillMount() {
        var userData = JSON.parse(localStorage.getItem("userData"));

        this.cid = userData.data.cid;
        var res = await this.props.getTransactions(this.cid);
        this.loadComponents(res.data.data);
        this.setState({ userData: userData.data, transactions: res.data.data });

        // console.log(userData.data.cid);

        this.poll = setInterval(this.startPoll, config.pollTime);

    }

    shouldComponentUpdate(nextProps, nextState) {
        if ((JSON.stringify(nextState.transactions) === JSON.stringify(this.state.transactions))
            && (JSON.stringify(nextState.allTransactions) === JSON.stringify(this.state.allTransactions))) {
            // console.log(nextState.transactions, this.state.transactions);
            return false;
        }
        else {
            // console.log("Database Upadated");
            this.loadComponents(nextState.transactions);
        }
        return true;
    }

    goToConfirmationPage(e) {
        var node = e.target;
        while(!node.attributes.txnid) node = node.parentNode;
        // console.log(node.attributes.txnID.value)
        // console.log(this.state.allTransactions[node.attributes.txnID.value])
        this.props.goToConfirmPage(this.state.allTransactions[node.attributes.txnid.value]);
    }

    loadComponents(transactions) {
        var transactionsComponent = [];
        var allTransactions = {};
        var notificationCount = 0;

        for (var key in transactions) {
            // console.log(transactions[key]);
            if (transactions[key].confirmed === "0") notificationCount++;
            // allTransactions.push(transactions[key]);
            allTransactions[transactions[key].txnID] = transactions[key];
            transactionsComponent.push(
                <Paper 
                    txnid={transactions[key].txnID}
                    onClick={this.goToConfirmationPage}
                    style={{width: "95%", height: "100px", textAlign: "left", marginTop: "10px", backgroundColor: "inherit", color: "inherit"}} 
                    elevation={3}
                >
                    <CardActionArea style={{height: "100%", padding: "10px", outline: "none"}}>
                        <Grid container>
                            <Grid item xs={8}>
                                <div style={{fontWeight: "bold"}}>{transactions[key].vehicleNo} ({transactions[key].vehicleType})</div>
                                <div style={{fontSize: "15px"}}>{transactions[key].location}</div>
                                <div style={{fontSize: "15px"}}>{transactions[key].timestamp}</div>
                            </Grid>
                            <Grid style={{}} item xs={4}>
                                <div style={{float: "right", fontSize: "20px", margin: "0px 10px 0 0"}}>₹ {transactions[key].charge}</div>
                                <ListItemIcon style={{width: "100%"}}>
                                    {
                                        (transactions[key].confirmed === "-1" || transactions[key].confirmed === "-2")
                                        ?(<ClearIcon style={{margin: "5px 10px 0 auto", fill: "rgb(255 30 30)", marginTop: "5px", width: "25px", height: "25px"}} />)
                                        :(transactions[key].confirmed === "0")?
                                        (<InfoOutlinedIcon style={{ margin: "5px 10px 0 auto", fill: "#ff7400", marginTop: "5px", width: "25px", height: "25px"}} />)
                                        :(transactions[key].confirmed === "1")
                                        ?(<CheckIcon style={{ margin: "5px 10px 0 auto", fill: "#7562e3", marginTop: "5px", width: "25px", height: "25px"}} />)
                                        :(null)
                                    }
                                    
                                </ListItemIcon>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Paper>
            );
        }

        this.props.updateNotificationCount(notificationCount);
        
        // console.log(transactions)
        this.setState({ allTransactions, transactionsComponent });
    }

    async startPoll() {
        var res = await this.props.getTransactions(this.cid);
        // console.log(res);
        this.setState({ transactions: res.data.data });

    }
    
    stopPoll() {
        clearInterval(this.poll);
    }

    componentWillUnmount() {
        this.stopPoll();
    }

    render() {
        return (
            <div style={{padding: "20px 0", paddingTop: "80px"}}>
                <center>
                    <div style={{textAlign: "center", fontSize: "23px"}}>Transactions</div>

                    {(this.state.allTransactions) && (this.state.transactionsComponent)}

                </center>

            </div>
        );
    }
}

export default Transactions;