import React from 'react';

import { Button } from '@material-ui/core';

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }


    render() {

        return (
            <div style={{padding: "20px 0", paddingTop: "80px"}}>
                <center>
                    <div style={{textAlign: "center", fontSize: "25px"}}>Profile</div>
                </center>

                <div style={{textAlign: "center", marginTop: "20px"}} >
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>Customer</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>customer@gmail.com</td>
                            </tr>
                            <tr>
                                <td>Phone no</td>
                                <td>9999999999</td>
                            </tr>
                            <tr>
                                <td>Bank IFSC</td>
                                <td>SBIN00000000</td>
                            </tr>
                            <tr>
                                <td>Bank Acc no</td>
                                <td>123456789123</td>
                            </tr>
                            <tr>
                                <td>Wallet Amount</td>
                                <td>955.176</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* <div className="update" style={{textAlign: "center", marginTop: "40px"}}>
                    <Button style={{margin: "10px", outline: "none", backgroundColor: "#e56219"}} color="primary" variant="contained">Change Password</Button>
                    <Button style={{margin: "10px", outline: "none", backgroundColor: "#349500"}} color="primary" variant="contained">Change Pin</Button>
                </div> */}
            </div>
        );
    }
}

export default Profile;