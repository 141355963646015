import React from 'react';

import axios from 'axios';

import config from "../config.json";

import { Snackbar } from '@material-ui/core';

import Error500 from './ErrorPages/Error500';
import Login from './Auth/Login';
import DashboardIndex from './Dashboard/DashboardIndex';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: null
        }

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);

        this.showMessage = this.showMessage.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        
        this.pages= {
            loginPage: <Login login={this.login} />,
            dashboardPage: <DashboardIndex logout={this.logout} />,
            error500: <Error500 />
        }
    }

    UNSAFE_componentWillMount() {
        if (!localStorage.getItem('host')) {
            localStorage.setItem('host', JSON.stringify(config.HOST));
        }
    }

    async componentDidMount() {
        var res = null;
        var content = null;
        var session = null;
        var userData = localStorage.getItem('userData');
        var host = JSON.parse(localStorage.getItem('host'));
        
        if (userData) {
            // console.log(!!userData)
            userData = JSON.parse(userData);
            session = userData.session;
            
            res = await axios.post(host+'/api/customers/isLoggedIn.php', { session }, { timeout: 1000 }).catch((e)=>{
                content = this.pages.error500;
            });
            // console.log(res);
            if (res) {
                // console.log(res);
                var isLoggedIn = res.data.isLoggedIn;
                
                if (isLoggedIn) content = this.pages.dashboardPage;
                else content = this.pages.loginPage;
                
            }

        }
        else {
            res = await axios.post(host+'/api/customers/isConnected.php', {}, { timeout: 1000 }).catch((e)=>{
                content = this.pages.error500;

            });
            if (res) {
                content = this.pages.loginPage;
            }
        }

        this.setState({ content, session });
    }

    async login(email, password) {
        // console.log(config);
        var host = JSON.parse(localStorage.getItem('host'));

        var res = await axios.post(host+'/api/customers/auth.php', { 
            login: true, 
            email: email, 
            password: password 
        });

        var data = res.data;

        if (!data.error) {
            localStorage.setItem('userData', JSON.stringify(data));

            var content = this.pages.dashboardPage;
            
            this.setState({ content });
            this.showMessage("Successfully Loggedin");
        }
        else {
            // localStorage.removeItem("userData");
            this.showMessage("Invalid Credentials");
        }
    }
    
    async logout () {
        var host = JSON.parse(localStorage.getItem('host'));

        var session = this.state.session;
        var res = await axios.post(host+'/api/customers/logout.php', { session });
        if (res) localStorage.removeItem("userData");

        this.setState({ content: this.pages.loginPage });
    }

    showMessage(message) {
        this.setState({ showSuccessMessage: true, snackMessage: message, showPrintPreview: true });
    }

    hideMessage() {
        this.setState({ showSuccessMessage: false });
    }

    render() {
        return (
            <>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.showSuccessMessage}
                    onClose={this.hideMessage}
                    message={this.state.snackMessage}
                    autoHideDuration={2000}
                />

                {this.state.content}
            </>
        );
    }
}

export default App;