import React from 'react';

import axios from 'axios';

import { Button, Dialog } from '@material-ui/core';


class ConfirmPage extends React.Component {
    constructor(props) {
        super(props);

        this.openConfirmationDialog = this.openConfirmationDialog.bind(this);
        this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this);

        this.state = {
            confirmationDialog: false,
            action: null
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ confirmed: this.props.transactionDetails.confirmed });
    }

    componentDidMount() {
        var transactionDetails = this.props.transactionDetails;
        var totalTime = 8*60*60*1000;

        var timeDiff = new Date() - new Date(transactionDetails.timestamp);
        if (totalTime - timeDiff > 0) this.msec2Hours(totalTime - timeDiff);
        
        this.runTimer = setInterval(()=>{
            var timeDiff = new Date() - new Date(transactionDetails.timestamp);

            if (totalTime - timeDiff > 0) this.msec2Hours(totalTime - timeDiff);
            else this.clearTimer();
        }, 1000);
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer() {
        clearInterval(this.runTimer);
    }
    
    openConfirmationDialog(action) {
        // console.log(action)
        this.setState({ confirmationDialog: true, action });
    }
    
    async closeConfirmationDialog(action) {
        var confirmed = "0";
        if (action === "yes") {
            if (this.state.action === "Confirm") confirmed = "1";
            else if (this.state.action === "Reject") confirmed = "-1";

            var host = JSON.parse(localStorage.getItem('host'));
            
            await axios.post(host+'/api/customers/updateTransaction.php', { 
                txnID: this.props.transactionDetails.txnID,
                confirmed: confirmed
             });
        }
        this.setState({ confirmationDialog: false, confirmed });
    }

    msec2Hours(msec) {
	    var total = msec

	    var milliseconds = total % 1000;
	    total = (total - (milliseconds)) / 1000;

	    var seconds = total % 60
	    total = (total - seconds) / 60;

	    var minutes = total % 60
	    total = (total - minutes) / 60;

	    var hours = total % 24

        // console.log(hours + " hours, " + minutes + " minutes, " + seconds + " seconds")
	    document.getElementById("timer").innerHTML = hours + " hours, " + minutes + " minutes, " + seconds + " seconds";
    }

    render() {
        var transactionDetails = this.props.transactionDetails;
        var theme = this.props.theme;

        return (
            <>
                <Dialog
                    open={this.state.confirmationDialog}
                    onClose={()=>this.closeConfirmationDialog("no")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <center style={{backgroundColor: theme.backgroundColor, color: theme.color, padding: "10px" }}>{"Confirmation"}</center>
                    {/* <br/> */}
                    <div style={{backgroundColor: theme.backgroundColor, color: theme.color, padding: "10px" }}>
                        Are you sure you want to <b>{this.state.action}</b> this transaction?
                    </div>
                    <div style={{backgroundColor: theme.backgroundColor, color: theme.color, padding: "10px", textAlign: "right" }}>
                        <Button onClick={()=>this.closeConfirmationDialog("no")} style={{outline: "none", color: theme.color}}  autoFocus>No</Button>
                        <Button onClick={()=>this.closeConfirmationDialog("yes")} style={{outline: "none", color: theme.color}} >Yes</Button>
                    </div>
                </Dialog>


                <div style={{padding: "20px 0", paddingTop: "80px"}}>
                    <center>
                        <div style={{textAlign: "center", fontSize: "25px"}}>Confirmation</div>
                        <div style={{textAlign: "center", fontSize: "35px", marginTop: "10px"}}>₹ {transactionDetails.charge}</div>
                        
                    </center>

                    <div style={{textAlign: "center", marginTop: "20px"}} >
                        <table className="table table-hover">
                            <tbody>
                                <tr>
                                    <td>Vehicle Number</td>
                                    <td>{transactionDetails.vehicleNo}</td>
                                </tr>
                                <tr>
                                    <td>Vehicle Type</td>
                                    <td>{transactionDetails.vehicleType}</td>
                                </tr>
                                <tr>
                                    <td>Location</td>
                                    <td>{transactionDetails.location}</td>
                                </tr>
                                <tr>
                                    <td>Transaction ID</td>
                                    <td>{transactionDetails.txnID}</td>
                                </tr>
                                <tr>
                                    <td>TimeStamp</td>
                                    <td>{transactionDetails.timestamp}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{marginTop: "30px", textAlign: "center"}}>
                        <div style={{fontSize: "20px", marginBottom: "10px"}}>Status</div>
                        {
                            (this.state.confirmed === "-2")
                            ?(<div style={{color: "red"}}>Not Acknowledged</div>)
                            :(this.state.confirmed === "-1")
                            ?(<div style={{color: "red"}}>Rejected</div>)
                            :(this.state.confirmed === "0")
                            ?(
                                <>
                                    <Button 
                                        onClick={()=>this.openConfirmationDialog("Confirm")}
                                        style={{margin: "10px", outline: "none", backgroundColor: "#349500"}} color="primary" variant="contained">Confirm</Button>
                                    <Button 
                                        onClick={()=>this.openConfirmationDialog("Reject")}
                                        style={{margin: "10px", outline: "none", backgroundColor: "#e56219"}} color="primary" variant="contained">Reject</Button>
                                </>
                            )
                            :(this.state.confirmed === "1")
                            ?(<div style={{color: "green"}}>Confirmed</div>)
                            :(null)
                        }
                        
                    </div>
                    <div className="autoRejectTimer" style={{display: (this.state.confirmed !== "0")?"none":"block", padding: "5px", fontSize: "14px", textAlign: "center"}}>This transaction will be automatically rejected in
                        <br /><div id="timer"></div>
                    </div>
                    
                </div>
            </>
        );
    }
}

export default ConfirmPage;